<template>
  <div data-app>
    <b-alert :show="successAlert.show" variant="success">
      {{ successAlert.text }}
    </b-alert>
    <b-alert :show="warningAlert.show" variant="danger">
      {{ warningAlert.text }}
    </b-alert>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="6" md="6" style="color: #e33354">
            <h3>{{ cardTitle }}</h3>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="3" md="3">
            <v-text-field
              v-model="search"
              append-icon="search"
              :label="$t('FORMS.search')"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="collection"
        :search="search"
        :sort-by="['name']"
        :footer-props="getDatatableFooterProps()"
        :items-per-page="getDatatableItemPerPage()"
        @click:row="handleClickItem"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <!-- <v-toolbar-title>My CRUD</v-toolbar-title> -->
            <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
            <!-- <v-spacer></v-spacer> -->
            <v-checkbox
              v-model="showAll"
              :label="$t('FORMS.active_countries')"
            ></v-checkbox>
            <!-- <v-spacer></v-spacer> -->
            <CountryModalForm
              :modalData="modalData"
              :permissions="permissions"
              @closeModalForm="handleCloseModalForm"
              @saveModalForm="handleSaveModalForm"
            ></CountryModalForm>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline"
                  >Are you sure you want to delete this item?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="closeDelete"
                    >Cancel</v-btn
                  >
                  <v-btn color="primary" @click="deleteItemConfirm">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.eu="{ item }">
          {{ eu[item.eu] }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ApiService from "@/core/services/api.service";
import CountryModalForm, { initialFormData } from "./CountryModalForm";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { listModelsMixins } from "@/view/mixins/listModelsMixins.js";
import {
  FETCH_COUNTRY,
  COUNTRY_COLLECTION,
} from "@/core/services/store/country.module.js";

export default {
  name: "Country",
  components: { CountryModalForm },
  mixins: [listModelsMixins],
  data() {
    return {
      cardTitle: this.$t("FORMS.countries"),
      showAll: true,
      search: "",
      collection: [],
      permissions: [],
      routePath: "/settings/country/",
      eu: [this.$t("TABLE.no"), this.$t("TABLE.yes")],
      successAlert: {
        show: false,
        text: "Success alert!",
      },
      warningAlert: {
        show: false,
        text: "Warning alert!",
      },
      headers: [
        {
          text: this.$t("TABLE.id"),
          align: "left",
          sortable: false,
          value: "id",
        },
        {
          text: this.$t("TABLE.country"),
          value: "name",
        },
        {
          text: this.$t("TABLE.language"),
          value: "locale",
        },
        {
          text: this.$t("TABLE.currency"),
          value: "currency_id",
        },
        {
          text: this.$t("TABLE.eu"),
          value: "eu",
        },
        { text: this.$t("TABLE.actions"), value: "actions", sortable: false },
      ],

      modalData: {
        dialog: false,
        editedIndex: -1,
        editedItem: {
          id: "",
          locale: "",
          eu: 1,
          currency_id: "EUR",
          translations: {
            en: { name: "" },
            hu: { name: "" },
          },
        },
        defaultItem: initialFormData,
      },

      dialogDelete: false,
    };
  },

  computed: {
    ...mapGetters([COUNTRY_COLLECTION]),
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    showAll() {
      if (this[COUNTRY_COLLECTION].length > 0) {
        this.filterCollection(this[COUNTRY_COLLECTION]);
      }
    },
    "$route.params.id": {
      handler: function (id) {
        if (id) {
          this.editItem(id);
        }
      },
      deep: true,
      immediate: true,
    },
    countryCollection(val) {
      // this.collection = val;
      this.filterCollection(val);
    },
  },

  methods: {
    ...mapActions([FETCH_COUNTRY]),
    editItem(id) {
      ApiService.get("country/" + id)
        .then(({ data }) => {
          this.modalData.editedIndex = 1;
          this.modalData.editedItem = Object.assign({}, data);
          this.modalData.dialog = true;
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
    },

    deleteItem(item) {
      this.editedIndex = this[COUNTRY_COLLECTION].indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      ApiService.delete("country/" + this.editedItem.id)
        .then(() => {
          this.fetchCountry();
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
      this.closeDelete();
    },

    handleCloseModalForm() {
      this.modalData.dialog = false;
      this.modalData.editedIndex = -1;
      this.modalData.editedItem = this.modalData.defaultItem;
      if (this.$route.name !== "country")
        this.$router.push({ name: "country" });
      // this.$router.push({ name: "country" });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    handleSaveModalForm(model) {
      if (this.modalData.editedIndex > -1) {
        ApiService.put("country/" + model.id, model)
          .then(() => {
            this.fetchCountry();
            this.handleCloseModalForm();
          })
          .catch((error) => {
            this.warningAlert.text = error.message;
            this.warningAlert.show = true;
            console.log("Error!: ", error);
          });
      } else {
        //create model
        ApiService.post("country", model)
          .then(({ data }) => {
            console.log(data);
            this.fetchCountry();
            this.handleCloseModalForm();
          })
          .catch((error) => {
            console.log("Error!: ", error);
          });
      }
    },

    filterCollection(coll) {
      if (!this.showAll) {
        this.collection = coll;
      } else {
        this.collection = coll.filter((item) => {
          return item.status == 1;
        });
      }
    },

    handleClickItem(item) {
      if (this.permissionCan("view") && !this.dialogDelete) {
        this.$router.push(this.routePath + item.id);
      }
    },

    permissionCan(action) {
      return this.permissions.find((x) => x.name == "system.country." + action);
    },

    setPermissions() {
      if (localStorage.permissions) {
        this.permissions = JSON.parse(localStorage.permissions).filter(
          (item) => item.name.indexOf("country") > -1
        );
      }
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "currency" }]);
    this.fetchCountry();
    this.setPermissions();
  },
};
</script>
